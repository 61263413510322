import { api, API_BASE_URL, APPLICATION_ID, ipAddress } from './config';

export const fetchMaintenanceStatus = async () => {
  try {
    const response = await api.get('/v1/pls/backend/maintenance-status');
    return response.data.data;
  } catch (error) {
    return error;
  }
};

let maintenanceStatusController: AbortController | null = null;

export const serverSentEventsMaintenance = async () => {
  maintenanceStatusController = new AbortController();

  try {
    const response = await fetch(
      `${API_BASE_URL}/v1/pls/backend/maintenance-status/sse`,
      {
        headers: {
          'IP-Address': ipAddress || 'N/A',
          'Application-Id': APPLICATION_ID || '',
          'Content-Type': 'text/event-stream',
        },
        signal: maintenanceStatusController.signal,
      }
    );

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader!.read();
      if (done) break;
      const chunk = decoder.decode(value, { stream: true });
      if (chunk.includes('Maintenance mode ended')) {
        window.location.href = '/';
        sessionStorage.clear();
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'AbortError') {
        console.error('Events request canceled');
      }
    }
  }
};
