import React from 'react';
import { DataNotFound, DotsIcon } from '@assets/images';
import { AccountTypeLogo, Spinner } from '@components/atom';
import { sumInvTopBottom } from '@interface/summaryInvesting';

interface props {
  header: string[];
  data: sumInvTopBottom;
  flex?: string;
  onClickItem?: (item: string[]) => void;
  isFetching?: boolean;
  widthColumns?: string[];
  numberColumn?: number[];
  pageName?: string;
}
export default function Table(props: props) {
  return (
    <>
      <div className="table-container">
        <table className="table table-top-bottom">
          <thead>
            <tr className="th-container">
              {props.header.map((item, index) => {
                if (item === 'Account Type' && props.pageName === 'SummaryInv')
                  return null;
                return (
                  <th
                    key={index}
                    align="right"
                    style={{
                      minWidth: props.widthColumns?.[index] || undefined,
                    }}
                  >
                    <div
                      className="th-content"
                      style={
                        props.numberColumn?.includes(index)
                          ? { justifyContent: 'end' }
                          : {}
                      }
                    >
                      {item}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {props.data.top.map((item, index) => (
              <tr
                key={index}
                className="cursor-pointer table-green"
                onClick={() => props.onClickItem && props.onClickItem(item)}
              >
                {item.map((childItem, childIndex) => {
                  const isNameColumn = props.header[childIndex] === 'Name';
                  const accountType = item[2];
                  if (
                    props.header[childIndex] === 'Account Type' &&
                    props.pageName === 'SummaryInv'
                  )
                    return null;
                  return (
                    <td
                      key={childIndex}
                      style={{
                        textAlign: props.numberColumn?.includes(childIndex)
                          ? 'right'
                          : 'inherit',
                      }}
                    >
                      {isNameColumn && props.pageName === 'SummaryInv' ? (
                        <div className="account-type">
                          <AccountTypeLogo accountType={accountType} />
                          {childItem}
                        </div>
                      ) : (
                        childItem
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}

            {!props.isFetching && props.data.top.length >= 1 && (
              <tr className="table-grey">
                {props.header.map((_, index) => {
                  if (
                    props.header[index] === 'Account Type' &&
                    props.pageName === 'SummaryInv'
                  )
                    return null;
                  return (
                    <td key={index} align="left">
                      <img
                        src={DotsIcon}
                        className="table-top-bottom-image-separator"
                        alt="mirae table separator"
                      />
                    </td>
                  );
                })}
              </tr>
            )}
            {!props.isFetching && props.data.bottom.length >= 1 && (
              <tr className="table-grey">
                {props.header.map((_, index) => {
                  if (
                    props.header[index] === 'Account Type' &&
                    props.pageName === 'SummaryInv'
                  )
                    return null;
                  return (
                    <td key={index} align="left">
                      <img
                        src={DotsIcon}
                        className="table-top-bottom-image-separator"
                        alt="mirae table separator"
                      />
                    </td>
                  );
                })}
              </tr>
            )}
            {props.data.bottom.map((item, index) => (
              <tr
                key={index}
                className="cursor-pointer table-red"
                onClick={() => props.onClickItem && props.onClickItem(item)}
              >
                {item.map((childItem, childIndex) => {
                  const isNameColumn = props.header[childIndex] === 'Name';
                  const accountType = item[2];
                  if (
                    props.header[childIndex] === 'Account Type' &&
                    props.pageName === 'SummaryInv'
                  )
                    return null;
                  return (
                    <td
                      key={childIndex}
                      style={{
                        textAlign: props.numberColumn?.includes(childIndex)
                          ? 'right'
                          : 'inherit',
                      }}
                    >
                      {isNameColumn && props.pageName === 'SummaryInv' ? (
                        <div className="account-type">
                          <AccountTypeLogo accountType={accountType} />
                          {childItem}
                        </div>
                      ) : (
                        childItem
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <Spinner show={props.isFetching} />
      </div>

      {!props.isFetching &&
        props.data.top.length < 1 &&
        props.data.bottom.length < 1 && (
          <div className="table-empty">
            <img src={DataNotFound} alt="" />
            <p>Data Not Found</p>
          </div>
        )}
    </>
  );
}
