import {
  naviDailyPerformanceDetailInterface,
  naviDailyPerformanceInterface,
} from "@interface/navidailyPerformance";
import {
  fetchDailyNaviTransactionAPI,
  fetchDailyNaviTransactionDetailAPI,
} from "@services/dailyNaviApi";
import { apiErrorHandler } from "@utils/errorHandler";
import { naviDailyArrString, naviDailyDetailArrString } from "@utils/formatter";
import { isStringExist } from "@utils/validation";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";

const DailyNaviHooks = () => {
  const [allDailyNaviTransactionData, setAllDailyNaviTransactionData] =
    useState<string[][]>([]);
  const [allDailyNaviPerformanceData, setAllDailyNaviPerformanceData] =
    useState<naviDailyPerformanceInterface>();
  const [isFetchingDailyNavi, setIsFetchingDailyNavi] = useState(false);
  const [fetchErrorTypeDailyNavi, setfetchErrorTypeDailyNavi] = useState("");
  const [monthDailyNavi, setMonthDailyNavi] = useState<string>(
    dayjs().format("YYYY-MM")
  );

  const [showDetail, setShowDetail] = useState(false);
  const [naviDate, setNaviDate] = useState<string>(dayjs().format("YYYY-MM"));
  const [searchText, setsearchText] = useState("");
  const [isFetchingDailyNaviDetail, setIsFetchingDailyNaviDetail] =
    useState(false);
  const [
    allDailyNaviTransactionDetailData,
    setAllDailyNaviTransactionDetailData,
  ] = useState<string[][]>([]);
  const [
    allDailyNaviPerformanceDetailData,
    setAllDailyNaviPerformanceDetailData,
  ] = useState<naviDailyPerformanceDetailInterface>();
  const [sortData, setSortData] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [totalDetailPages, setTotalDetailPages] = useState(1);
  const [detailPage, setDetailPage] = useState(1);

  const debounceSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    if (isStringExist(sessionStorage.getItem("access_token"))) {
      const naviDailyPerformanceInterface: naviDailyPerformanceInterface = {};
      const getNaviTransaction = async function fetchDailyNaviTransaction() {
        try {
          setAllDailyNaviTransactionData([]);
          setAllDailyNaviPerformanceData(naviDailyPerformanceInterface);
          setIsFetchingDailyNavi(true);
          const result = await fetchDailyNaviTransactionAPI({
            month: monthDailyNavi,
          });
          setAllDailyNaviTransactionData(
            naviDailyArrString(result.naviTransactions, 0)
          );
          setAllDailyNaviPerformanceData(result.fundPerformance);
        } catch (error) {
          setfetchErrorTypeDailyNavi(apiErrorHandler(error));
        } finally {
          setIsFetchingDailyNavi(false);
        }
      };
      getNaviTransaction();
    }
  }, [monthDailyNavi]);

  useEffect(() => {
    if (isStringExist(sessionStorage.getItem("access_token")) && showDetail) {
      if (searchText.length > 2 || searchText.length === 0) {
        getNaviTransactionDetail();
      }
    }
  }, [naviDate, debounceSearchText, showDetail, sortData, transactionType]);

  const naviDailyPerformanceDetailInterface: naviDailyPerformanceDetailInterface =
    {};
  const getNaviTransactionDetail = async function fetchDailyNaviTransaction() {
    try {
      setAllDailyNaviTransactionDetailData([]);
      setDetailPage(1);
      setAllDailyNaviPerformanceDetailData(naviDailyPerformanceDetailInterface);
      setIsFetchingDailyNaviDetail(true);
      setfetchErrorTypeDailyNavi("");
      const result = await fetchDailyNaviTransactionDetailAPI({
        page: 1,
        date: naviDate,
        query: searchText.length > 2 ? searchText : "",
        sort: sortData.length > 2 ? sortData : "",
        transactionType: transactionType,
      });

      setAllDailyNaviPerformanceDetailData(result.fundPerformanceDetail);

      if (result.hasOwnProperty("pagination")) {
        setAllDailyNaviTransactionDetailData(
          naviDailyDetailArrString(result.pagination?.content, 0)
        );
        setTotalDetailPages(result.pagination.totalPages);
        setDetailPage(2);
      }
    } catch (error) {
      setfetchErrorTypeDailyNavi(apiErrorHandler(error));
    } finally {
      setIsFetchingDailyNaviDetail(false);
    }
  };

  const nextNaviTransactionDetail = async function fetchDailyNaviTransaction() {
    try {
      setfetchErrorTypeDailyNavi("");
      setIsFetchingDailyNaviDetail(true);
      const result = await fetchDailyNaviTransactionDetailAPI({
        page: detailPage,
        date: naviDate,
        query: searchText.length > 2 ? searchText : "",
        sort: sortData.length > 2 ? sortData : "",
        transactionType: transactionType,
      });
      if (result.hasOwnProperty("pagination")) {
        setAllDailyNaviTransactionDetailData([
          ...allDailyNaviTransactionDetailData,
          ...naviDailyDetailArrString(
            result.pagination.content,
            allDailyNaviTransactionDetailData.length
          ),
        ]);
        setTotalDetailPages(result.pagination.totalPages);
        setDetailPage(detailPage + 1);
      }
      setAllDailyNaviPerformanceDetailData(result.fundPerformanceDetail);
    } catch (error) {
      setfetchErrorTypeDailyNavi(apiErrorHandler(error));
    } finally {
      setIsFetchingDailyNaviDetail(false);
    }
  };

  return {
    monthDailyNavi,
    setMonthDailyNavi,
    allDailyNaviTransactionData,
    setAllDailyNaviTransactionData,
    allDailyNaviPerformanceData,
    isFetchingDailyNavi,
    setIsFetchingDailyNavi,
    fetchErrorTypeDailyNavi,
    setfetchErrorTypeDailyNavi,
    showDetail,
    setShowDetail,
    naviDate,
    setNaviDate,
    searchText,
    setsearchText,
    isFetchingDailyNaviDetail,
    allDailyNaviPerformanceDetailData,
    allDailyNaviTransactionDetailData,
    setSortData,
    setTransactionType,
    totalDetailPages,
    detailPage,
    nextNaviTransactionDetail,
    setDetailPage,
  };
};

export default DailyNaviHooks;
