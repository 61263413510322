import { CloseIcon, ForceLogin } from '@assets/images';
import { Modal } from 'rsuite';

interface Props {
  isShow: boolean;
  onClose: () => void;
}

const ForceLoginPopUp = (props: Props) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      open={props.isShow}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
      onClose={props.onClose}
    >
      <Modal.Body style={{ width: '279px' }}>
        <div
          style={{
            justifyContent: 'end',
            display: 'flex',
            padding: '10px',
            cursor: 'pointer',
          }}
        >
          <img src={CloseIcon} alt="close" onClick={props.onClose} />
        </div>

        <div
          style={{ justifyContent: 'center', display: 'flex', padding: '10px' }}
        >
          <img src={ForceLogin} alt="Force Login" />
        </div>

        <div className="confirm-content">
          <h1 className="force-title">FORCE LOGOUT</h1>
          <h2 className="force-desc">
            You are logged in on a different device
          </h2>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="confirm-footer">
          <button className="button-primary" onClick={props.onClose}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ForceLoginPopUp;
