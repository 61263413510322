import { Document, Logo } from '@assets/images';
import { IoCard, IoHome, IoStatsChart } from 'react-icons/io5';
import { FiLogOut } from 'react-icons/fi';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSidebar } from 'context/sidebarContext';
import { cancelSessionStatusRequest } from '@services/config';

const menus = [
  {
    name: 'Summary Investing Performance',
    icon: <IoHome />,
    path: '/summary-investing',
  },
  {
    name: 'Daily Navi Transaction',
    icon: <IoStatsChart />,
    path: '/daily-navy',
  },
  {
    name: 'Portofolio Performance',
    icon: <IoCard />,
    path: '/portfolio-performance',
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isCollapsed, toggleSidebar } = useSidebar();

  const onLogOut = () => {
    sessionStorage.clear();
    navigate('/');
    cancelSessionStatusRequest();
  };

  return (
    <div className="container-sidebar">
      <div className={`sidebar ${isCollapsed ? 'is-collapsed' : ''}`}>
        <div className="sidebar-content">
          <div className="logo">
            <img
              src={Logo}
              alt="Portalis"
              style={{ width: isCollapsed ? '30px' : '16px' }}
            />

            {!isCollapsed ? <h1>Portalis</h1> : null}
          </div>
          <div className="collapse-icon" onClick={toggleSidebar}>
            {isCollapsed ? <RiArrowRightSLine /> : <RiArrowLeftSLine />}
          </div>
          {menus.map((item, idx) => (
            <div
              className={`item ${
                location.pathname === item.path ? 'active' : ''
              }`}
              key={idx}
              onClick={() => navigate(item.path)}
            >
              <div className="icon">{item.icon}</div>
              {isCollapsed ? null : <div className="title">{item.name}</div>}
            </div>
          ))}
        </div>

        <div className="footer-sidebar">
          {!isCollapsed && (
            <div className="illustration">
              <img src={Document} alt="Portalis" />
            </div>
          )}

          <div className="logout" onClick={onLogOut}>
            <FiLogOut />
            {!isCollapsed && <span>Log out</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
