import {
  DayTradeLogo,
  MarginLogo,
  RegularLogo,
  SyariahLogo,
} from '@assets/images';
import { Tooltip, Whisper } from 'rsuite';

const AccountTypeLogo = ({ accountType }: { accountType: string }) => {
  const logos: { [key: string]: string } = {
    Syariah: SyariahLogo,
    'Day Trading': DayTradeLogo,
    'Margin Trading': MarginLogo,
    Regular: RegularLogo,
  };
  const logo = logos[accountType] || null;

  if (!logo) {
    return null;
  }

  return (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={<Tooltip>{accountType}</Tooltip>}
    >
      <img src={logo} alt={accountType} />
    </Whisper>
  );
};

export default AccountTypeLogo;
