import React, { useEffect } from "react";
import { LoginFormCard } from "@components/card";
import { LoginDots, LoginThumbnail } from "@assets/images";
import { fetchMaintenanceStatus } from "@services/maintenance";
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const navigate = useNavigate();
  
  const getMaintenanceStatus = async () => {
    try {
      const response = await fetchMaintenanceStatus();
      if (response?.maintenance) {
        navigate('/maintenance', { replace: true });
        sessionStorage.clear();
      } else {
        navigate('/', { replace: true });
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getMaintenanceStatus();
  }, []);;

  return (
    <div
      className="login-page d-flex justify-space-between mt-50"
      style={{ padding: "0px 114px" }}
    >
      <img
        alt="mirae-login-portal"
        className="login-shadow-image"
        src={LoginDots}
      />
      <LoginFormCard />
      <img
        alt="mirae-login-portal"
        className="login-thumbnail"
        src={LoginThumbnail}
      />
    </div>
  );
}
