import { forwardRef, useEffect, useState } from 'react';
import { CrossSolid, SearchIcon, SwitchIcon, TrophyBlue } from '@assets/images';
import { Spinner } from '@components/atom';
import { Table, TableTopBottom } from '@components/molecule';
import PopUpDetail from '@components/organism/popupDetail';
import sumInvHooks from '@hooks/sumInvHooks';
import { useCheckTokenExpiration } from '@hooks/useCheckTokenExpiration';
import {
  summaryInvestingTableFilter,
  summaryInvestingTableHeader,
} from '@interface/summaryInvesting';
import { isStringExist } from '@utils/validation';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import SortDropdown from '@components/organism/sortDropdown';
import { numberFormatter } from '@utils/formatter';
import { Watermark } from '@hirohe/react-watermark';
import { BiSort } from 'react-icons/bi';
import { IoChevronDownOutline } from 'react-icons/io5';
import { WATERMARK_DENSITY } from 'config/watermark';

const CustomInputMonth = forwardRef(({ value, onClick }: any, ref: any) => (
  <button
    className="input-calendar ml-10 cursor-pointer gap-5"
    style={{ padding: '10px 12px' }}
    type="button"
    onClick={onClick}
    ref={ref}
  >
    <p>{value}</p>
    <IoChevronDownOutline />
  </button>
));

export default function SummartInvTransactionTabData() {
  useCheckTokenExpiration();

  const [showPopUpDetail, setShowPopUpDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const navigate = useNavigate();
  const {
    month,
    setMonth,
    searchText,
    setsearchText,
    isTopBottom,
    setIsTopBottom,
    allData,
    nextSumInv,
    isFetching,
    maxFetchingPage,
    fetchingPage,
    fetchigErrorType,
    topBottomData,
    setSortData,
    setSortDirection,
    transactionValueTotal,
    setDataArr,
    dataArr,
    setAllData,
    fetchTopBottom,
  } = sumInvHooks();

  const handleDatePicker = (date: any) => {
    setsearchText('');
    setMonth(date);
  };
  const handleSortData = (header: string, direction: string) => {
    setSortData(header);
    setSortDirection(direction);
  };

  const handleShowPopUpDetail = (show: boolean) => {
    setShowPopUpDetail(show);
    document.body.style.overflow = show ? 'hidden' : 'scroll';
  };

  useEffect(() => {
    if (
      !isStringExist(sessionStorage.getItem('access_token')) ||
      fetchigErrorType === 'jwt-expired'
    ) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchigErrorType]);

  useEffect(() => {
    setsearchText('');
  }, [isTopBottom]);

  return (
    <div className="summary">
      <Watermark
          opacity={0.3}
          textSize={16}
          text={'&#160;' + sessionStorage.getItem('fullName') ?? ''}
          gutter={WATERMARK_DENSITY}
        >
      <PopUpDetail
        isShow={showPopUpDetail}
        query={selectedItem}
        date={month}
        onClose={() => handleShowPopUpDetail(false)}
        setData={setDataArr}
        data={dataArr}
        setAllData={setAllData}
        topBottomData={topBottomData}
        fetchTopBottom={fetchTopBottom}
      />
      <div className="header-summary">
        <div className="box-profile">
          <span className="full-name">
            {sessionStorage.getItem('fullName') || '-'}
          </span>
          <span className="employee-id">
            {sessionStorage.getItem('employee_id') || '-'}
          </span>
        </div>

        <div>
          <div className="table-filter pv-12 border-radius-top d-flex align-center gap-filter">
            <div className="date-picker-container">
              <div className="date-picker">
                <span className="ml-12">Month & Year : </span>
                <DatePicker
                  customInput={<CustomInputMonth />}
                  maxDate={new Date(dayjs().format('YYYY-MM'))}
                  minDate={
                    new Date(
                      dayjs()
                        .subtract(4, 'year')
                        .set('month', 0)
                        .format('YYYY-MM')
                    )
                  }
                  selected={new Date(month)}
                  onChange={(date: any) => handleDatePicker(date)}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                />
              </div>
              <div className="transaction-info-wrapper">
                <span>Total Transaction Value</span>
                <div className="transaction-value-info">
                  <img src={TrophyBlue} alt="" />
                  <span>Rp {numberFormatter(transactionValueTotal)}</span>
                </div>
              </div>
            </div>
            <div
              className={`search-box bg-white d-flex ${
                isTopBottom && 'bg-disabled'
              }`}
            >
              <img src={SearchIcon} alt="search-icon" className="search-icon" />
              <input
                placeholder="Search YP ID or Client Name"
                value={searchText}
                onChange={(e) =>
                  setsearchText(e.target.value.replace(/\s+/g, ' '))
                }
                disabled={isTopBottom}
                className={isTopBottom ? 'bg-disabled' : ''}
              />
            </div>
            <SortDropdown
              disabled={isTopBottom}
              options={summaryInvestingTableFilter}
              handleSortData={handleSortData}
              placeholder="Sort By"
              label=""
              sortIcon
              icon={<BiSort />}
            />
            <div
              onClick={() => {
                setIsTopBottom(!isTopBottom)
                setAllData([]);
              }}
              className={`d-flex align-center ${
                !isTopBottom ? 'switch-button' : 'switch-button-ghost'
              }`}
            >
              <p>Top 5 & Bottom 5</p>
              <img
                src={!isTopBottom ? SwitchIcon : CrossSolid}
                alt="switch-icon"
                className="switch-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="box-table">
          <div className="table-container">
            {isTopBottom && (topBottomData.bottom.length || topBottomData.top.length) ? (
              <TableTopBottom
                header={summaryInvestingTableHeader}
                onClickItem={(e) => {
                  handleShowPopUpDetail(true);
                  setSelectedItem(e[1]);
                }}
                data={topBottomData}
                isFetching={isFetching}
                numberColumn={[1, 8, 9, 10, 11, 12, 13, 15]}
                widthColumns={[
                  '10px',
                  '50px',
                  '50px',
                  '180px',
                  '80px',
                  '80px',
                  '80px',
                  '80px',
                  '80px',
                  '110px',
                  '80px',
                  '80px',
                  '80px',
                  '100px',
                  '100px',
                  '100px',
                ]}
                pageName='SummaryInv'
              />
            ) : (
              <InfiniteScroll
                dataLength={allData.length}
                next={nextSumInv}
                hasMore={!isFetching && fetchingPage <= maxFetchingPage}
                loader={<Spinner />}
                height={allData.length > 1 ? '35vw' : "auto"}
              >
                <div className="column-hover">
                  <Table
                    isFetching={isFetching}
                    header={summaryInvestingTableHeader}
                    onClickItem={(e) => {
                      handleShowPopUpDetail(true);
                      setSelectedItem(e[1]);
                    }}
                    fetchigErrorType={fetchigErrorType}
                    data={allData}
                    numberColumn={[1, 8, 9, 10, 11, 12, 13, 15]}
                    highlightSearch={[3]}
                    searchText={searchText}
                    widthColumns={[
                      '10px',
                      '50px',
                      '50px',
                      '180px',
                      '80px',
                      '80px',
                      '80px',
                      '80px',
                      '80px',
                      '110px',
                      '80px',
                      '80px',
                      '80px',
                      '100px',
                      '100px',
                      '100px',
                    ]}
                    pageName='SummaryInv'
                  />
                </div>
              </InfiniteScroll>
            )}
          </div>
      </div>
      </Watermark>
    </div>
  );
}
