import { ChevDownGray, SearchIcon } from "@assets/images";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import React, { ReactNode, useEffect, useState } from "react";

interface sortDropdownInterface {
  options: {
    label: string;
    field: string;
    default?: boolean;
    defaultSortType?: string;
  }[];
  label?: string;
  sortBtn?: boolean;
  placeholder?: string;
  disabled?: boolean | undefined;
  defaultOption?: boolean;
  defaultSort?: string;
  search?: boolean;
  handleSortData: (option: string, direction: string) => void;
  className?: string; 
  value?: string;
  sortData?: string;
  sortIcon?: boolean;
  icon?: ReactNode;
}

const SortDropdown = ({
  options,
  handleSortData,
  sortBtn = true,
  label = "Sort by",
  placeholder = "Choose One",
  disabled = false,
  defaultSort = "asc",
  defaultOption,
  search = false,
  className,
  value,
  sortData,
  sortIcon,
  icon
}: sortDropdownInterface) => {
  const [sortDirection, setSortDirection] = useState(defaultSort);
  const [searchLabel, setSearchLabel] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [sort, setSort] = useState<any>({
    showPopup: false,
    selected: "",
    label: "",
  });

  const popUpRef = useOnClickOutside(() => {
    setShowPopup(!showPopup)
  });

  useEffect(() => {
    if (defaultOption) {
      const defaultOpt = options.find((option) => option.default);
      if (defaultOpt) {
        setSort({
          selected: defaultOpt.field,
          label: defaultOpt.label,
        });
        setSortDirection(defaultOpt.defaultSortType || defaultSort);
      }
    }
  }, [options, defaultOption, defaultSort]);

  useEffect(() => {
    sort.selected &&
      sortDirection &&
      handleSortData(sort.selected, sortDirection);
  }, [sortDirection, sort]);

  useEffect(() => {
    const selected = options.find((option) => option.field === value);
    if (value && selected) {
      setSort({
        selected: selected?.field,
        label: selected?.label,
      });
      setSearchLabel(selected?.label)
    }
  }, [value]);

  useEffect(() => {
    if (sortData) {
      setSortDirection(sortData.split(' ')[1]);
    }
  }, [sortData])

  return (
    <div className={`sort-container table-filter ${className}`}>
      {label ? <p className="label">{label}</p> : null}
      {search ? (
        <div className="sort-popup-btn search-sort" aria-disabled={disabled}>
          <img src={SearchIcon} alt="search-icon" className="search-icon" />
          <input
            disabled={disabled}
            type="text"
            value={searchLabel}
            className={`${
              disabled ? "bg-disabled" : ""
            } input-search cursor-pointer`}
            placeholder={sort.label ? sort.label : placeholder}
            onClick={() =>
              setShowPopup(!showPopup)
            }
            onChange={(e) => {
              setSearchLabel(e.target.value)
              if (!e.target.value) {
                setSort((params: any) => ({
                  ...params,
                  selected: "",
                  label: "",
                }))
                handleSortData("", sortDirection);
              }
            }}
          />
          {!disabled && showPopup && (
            <div ref={popUpRef} className="sort-popup-container">
              {options
                .filter((option) => {
                  if (searchLabel) {
                    return option.label
                      .toLowerCase()
                      .includes(searchLabel.toLowerCase());
                  } else {
                    return option;
                  }
                })
                .map((option, key) => (
                  <span
                    style={{cursor: 'pointer'}}
                    key={key}
                    onClick={() => {
                      setSort((params: any) => ({
                        ...params,
                        selected: option.field,
                        label: option.label,
                      }))
                      setSearchLabel(option.label)
                      setShowPopup(!showPopup)
                     }
                    }
                  >
                    {option.label}
                  </span>
                ))}
            </div>
          )}
        </div>
      ) : (
        <button
          onClick={() =>
            setShowPopup(!showPopup)
          }
          className={`${
            disabled ? "bg-disabled" : ""
          } input-calendar ml-10 cursor-pointer sort-popup-btn`}
          style={{ padding: "10px 12px", gap: '8px'}}
          type="button"
        >
          <p className="font-bold">{sort.label ? sort.label : placeholder}</p>
          {sortIcon ? icon : null}
          {!disabled && showPopup && (
            <div ref={popUpRef} className="sort-popup-container">
              {options.map(
                (option, key) =>
                  key !== 0 && (
                    <span
                      key={key}
                      onClick={() => {
                        setSort((params: any) => ({
                          ...params,
                          selected: option.field,
                          label: option.label,
                        }))
                        if (option.defaultSortType) {
                          setSortDirection(option.defaultSortType);
                        }
                      }}
                    >
                      {option.label}
                    </span>
                  )
              )}
            </div>
          )}
        </button>
      )}

      {!disabled && sortBtn && sort.selected && (
        <div className="sort-button">
          <div
            onClick={() => setSortDirection("asc")}
            className={`rotate-180 ${sortDirection === "asc" ? "active" : ""}`}
          >
            <img src={ChevDownGray} alt="" />
          </div>
          <div
            onClick={() => setSortDirection("desc")}
            className={`${sortDirection === "desc" ? "active" : ""}`}
          >
            <img src={ChevDownGray} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SortDropdown;
