import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { loginSchema } from '@utils/yupSchema'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import SmSpinner from '@components/atom/smspinner'
import { api, serverSentEventsSession } from '@services/config'
import HCaptcha from '@hcaptcha/react-hcaptcha';

export default function LoginFormCard() {
    const [userPasswordNotMatch, setUserPasswordNotMatch] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [captchaToken, setCaptchaToken] = useState("");

    const initialValues = {
        userid: '',
        password: '',
    }

    const navigate = useNavigate()

    const login = async (item: any) => {
        if (!navigator.onLine) {
            setError("Internet connection appears to be offline. Please check your connection.");
            return; // Exit the function early
        }

        if (!captchaToken && process.env.REACT_APP_USE_CAPTCHA === '1') {
            setError('Please complete the hCaptcha verification.');
            return;
          }

        setIsLoading(true);
        setError('');

        try {
            const response = await api.post ('/v1/pls/backend/employee-auth',
                {
                    username: item.userid,
                    password: item.password,
                    ...(process.env.REACT_APP_USE_CAPTCHA === '1' && { captchaToken }),
                },
            )

            const now = new Date();
            const expiry = now.getTime() + response.data.expires_in * 1000; 
            
            sessionStorage.setItem('token_type', response.data.token_type)
            sessionStorage.setItem('access_token', response.data.access_token)
            sessionStorage.setItem('refresh_token', response.data.refresh_token)
            sessionStorage.setItem('email', response.data.email)
            sessionStorage.setItem('fullName', response.data.fullName)
            sessionStorage.setItem('employee_id', item.userid)
            sessionStorage.setItem('expires_in', expiry.toString())

            serverSentEventsSession(response.data.access_token)
            
            navigate('/summary-investing')
            setUserPasswordNotMatch(false)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Check if the error is a 403 Forbiden
                if (error.response && error.response.status === 403 || error.response && error.response.status === 401 && error.response?.data.message !== "Invalid captcha") {
                    // Handle 403 Forbiden specifically
                    setError('Incorrect User ID or Password!')
                    // return;
                } else {
                    setError(error.response?.data.message || 'Oops, something went wrong. Please try again later.');
                }

                // return;
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleLogoutAccess = () => {
        if (process.env.REACT_APP_FORGOT_PASSWORD_PATH) {
            window.location.href = process.env.REACT_APP_FORGOT_PASSWORD_PATH
        }
    }

    return (
        <div className="login-form-card">
            <div className="text-wrapper">
                Welcome to Portalis
                <div className="underline"></div>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={login}
                validationSchema={loginSchema}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="form-container">
                            <label className="text-color-black-200">Username <span className='text-red'>*</span></label>
                            <Field
                                type="text"
                                name="userid"
                                id="userid"
                                className="input login-input mb-10 mt-10"
                                placeholder="Input your employee number "
                            />
                            <label className="text-color-black-200">Password <span className='text-red'>*</span></label>
                            <Field
                                type="password"
                                name="password"
                                id="password"
                                className="input login-input mb-10 mt-10"
                                placeholder="Input your Password"
                            />
                            {/* {userPasswordNotMatch && (
                                <p className="text-color-red text-12">
                                    Incorrect User ID or Password!
                                </p>
                            )} */}
                            {error && <p className="text-color-red text-12">{error}</p>}
                            <p onClick={() => handleLogoutAccess()} className="text-color-orange cursor-pointer text-right mb-24">
                                Forgot Password?
                            </p>
                            {process.env.REACT_APP_USE_CAPTCHA === '1' && (
                             <HCaptcha
                                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                                onVerify={(token) => setCaptchaToken(token)}
                                onExpire={() => setCaptchaToken('')}
                             />
                            )}
                            <button 
                                type="submit" 
                                style={{fontSize: '1.6vmin'}} 
                                disabled={isSubmitting || !values?.userid || !values?.password} 
                                className={`mt-24 button filled ${isLoading || !values?.userid || !values?.password?  'btn-disabled' : ''}`}
                            >
                                {!isLoading ? 'Log In' : <SmSpinner label='Please wait'/>}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
