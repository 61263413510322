import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_WEB_PORTALIS_BASE_URL;
export const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export let ipAddress: string | null = null;
let sessionExpiredCallback: (() => void) | null = null;
let sessionStatusController: AbortController | null = null;

export const setSessionExpiredCallback = (callback: () => void) => {
  sessionExpiredCallback = callback;
};

const fetchIPAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    ipAddress = response.data.ip || 'N/A';
  } catch (error) {
    console.error('Error fetching the IP address:', error);
    ipAddress = 'N/A';
  }
};

fetchIPAddress();

api.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('access_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (!ipAddress || ipAddress === 'N/A') {
      await fetchIPAddress();
    }

    config.headers['IP-Address'] = ipAddress || 'N/A';
    config.headers['Application-Id'] = APPLICATION_ID;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = error.response?.data?.error;
    if (error.response?.status === 401) {
      if (errorMessage === 'Session expired or logged in elsewhere') {
        if (sessionExpiredCallback) {
          sessionExpiredCallback();
        }
      }
    } else if (error.response?.status === 503) {
      if (
        errorMessage ===
        'The system is currently under maintenance. Please try again later.'
      ) {
        window.location.href = '/maintenance';
      }
    }
    return Promise.reject(error);
  }
);

export const cancelSessionStatusRequest = () => {
  if (sessionStatusController) {
    sessionStatusController.abort();
    sessionStatusController = null;
  }
};

export const serverSentEventsSession = async (token: string | null) => {
  if (!token) return;

  if (!ipAddress || ipAddress === 'N/A') {
    await fetchIPAddress();
  }

  sessionStatusController = new AbortController();

  try {
    const response = await fetch(
      `${API_BASE_URL}/v1/pls/backend/session-status`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'IP-Address': ipAddress || 'N/A',
          'Application-Id': APPLICATION_ID || '',
          'Content-Type': 'text/event-stream',
        },
        signal: sessionStatusController.signal,
      }
    );

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader!.read();
      if (done) break;
      const chunk = decoder.decode(value, { stream: true });
      if (chunk.includes('Session expired')) {
        if (sessionExpiredCallback) {
          sessionExpiredCallback();
        }
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'AbortError') {
        console.error('Session status request canceled');
      }
    }
  }
};
