'use client';

import React, { useEffect, useState } from 'react';
import Footer from './footer';
import { useLocation } from 'react-router-dom';
import Sidebar from '@components/molecule/sidebar';
import Navbar from './navbar';

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      setIsLoginPage(true);
    } else {
      setIsLoginPage(false);
    }
    setIsLoading(false);
  }, [location]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {!isLoginPage ? (
        <div className="main-body">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content">{children}</div>
        </div>
      ) : (
        <div className="layout-main">
          <Navbar />
          {children}
        </div>
      )}
      <Footer />
    </>
  );
}
