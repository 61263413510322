import { DataNotFound } from '@assets/images';
import { base64ForConnectionError } from '@assets/images/base64';
import { AccountTypeLogo, Spinner } from '@components/atom';
import { getColor, highlightSearchText } from '@utils/formatter';

interface props {
  header: string[];
  data: string[][];
  flex?: string;
  onClickItem?: (item: string[]) => void;
  isFetching?: boolean;
  numberColumn?: number[];
  highlightColumns?: number[];
  boldColumns?: number[];
  widthColumns?: string[];
  highlightSearch?: number[];
  searchText?: string;
  fetchigErrorType?: string | number;
  pageName?: string;
}
export default function Table(props: props) {
  const ERROR_TYPES = [404, 500, 502, 503, 504];

  const isConnectionError = (fetchigErrorType: string | number | undefined) =>
    !navigator.onLine || ERROR_TYPES.includes(fetchigErrorType as number);

  const isDataEmpty = (props: props) =>
    !props.isFetching &&
    navigator.onLine &&
    props.data.length < 1 &&
    !ERROR_TYPES.includes(props.fetchigErrorType as number);

  return (
    <div style={{ backgroundColor: 'white', height: '100%' }}>
      <table className="table">
        <thead>
          <tr className="th-container">
            {props.header.map((item, index) => {
              if (item === 'Account Type' && props.pageName === 'SummaryInv')
                return null;
              return (
                <th
                  key={index}
                  align="right"
                  style={{ minWidth: props.widthColumns?.[index] || undefined }}
                >
                  <div
                    className="th-content"
                    style={
                      props.numberColumn?.includes(index)
                        ? { justifyContent: 'end' }
                        : {}
                    }
                  >
                    {item}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {navigator.onLine &&
            props.data.map((item, index) => (
              <tr
                key={index}
                style={{ cursor: props.onClickItem ? 'pointer' : 'default' }}
                onClick={() => props.onClickItem && props.onClickItem(item)}
              >
                {item.map((childItem, childIndex) => {
                  const isNameColumn = props.header[childIndex] === 'Name';
                  const accountType = item[2];
                  if (
                    props.header[childIndex] === 'Account Type' &&
                    props.pageName === 'SummaryInv'
                  )
                    return null;
                  return (
                    <td
                      key={childIndex}
                      data-value={childItem}
                      style={{
                        textAlign: props.numberColumn?.includes(childIndex)
                          ? 'right'
                          : 'inherit',
                        color: props.highlightColumns?.includes(childIndex)
                          ? getColor(childItem)
                          : props.pageName === 'DailyNaviSummary' &&
                            childIndex === 1
                          ? '#f58220'
                          : '',
                        fontWeight:
                          props.highlightColumns?.includes(childIndex) ||
                          props.boldColumns?.includes(childIndex)
                            ? 700
                            : '',
                      }}
                    >
                      {isNameColumn && props.pageName === 'SummaryInv' ? (
                        <div className="account-type">
                          <AccountTypeLogo accountType={accountType} />
                          {props.highlightSearch?.includes(childIndex) &&
                          props.searchText
                            ? highlightSearchText(childItem, props.searchText)
                            : childItem}
                        </div>
                      ) : props.highlightSearch?.includes(childIndex) &&
                        props.searchText ? (
                        highlightSearchText(childItem, props.searchText)
                      ) : (
                        childItem
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
      {props.isFetching && <Spinner show={props.isFetching} />}
      {isDataEmpty(props) && (
        <div className="table-empty">
          <img src={DataNotFound} alt="Data Not Found" />
          <p>Data Not Found</p>
        </div>
      )}

      {isConnectionError(props.fetchigErrorType) && (
        <div className="table-empty">
          <img src={base64ForConnectionError} alt="Connection Error" />
          <p>Connection Error</p>
          <button type="button" onClick={() => window.location.reload()}>
            Refresh Data
          </button>
        </div>
      )}
    </div>
  );
}
