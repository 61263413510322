import { MaintenanceIllustration } from '@assets/images';
import { CountdownTimer } from '@components/molecule';
import { serverSentEventsMaintenance } from '@services/maintenance';
import React, { useEffect } from 'react';

const Maintenance = () => {
  useEffect(() => {
    serverSentEventsMaintenance();
  }, []);

  const maintenanceEndTimeDuration = process.env.REACT_APP_MAINTENANCE_DURATION
    ? parseInt(process.env.REACT_APP_MAINTENANCE_DURATION)
    : 60;

  return (
    <div className="maintenance">
      <div className="content">
        <div className="illustration">
          <img src={MaintenanceIllustration} alt="Maintenance" loading="lazy" />
        </div>

        <h1 className="title">
          PORTALIS <br /> is currently under maintenance
        </h1>

        <span className="description flex-center">
          Please try to access again in
          <CountdownTimer
            maintenanceEndTimeDuration={maintenanceEndTimeDuration}
            repeat
          />
        </span>

        <span className="description">
          If you have an urgent inquiries, please reach out to our email
          <br />
          <a className="email" href="mailto:portalis@miraeasset.co.id">
            portalis@miraeasset.co.id
          </a>
        </span>
      </div>
    </div>
  );
};

export default Maintenance;
