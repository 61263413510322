import { getMaintenanceEndTimeFromDuration } from '@utils/maintenanceUtils';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface CountdownTimerProps {
  maintenanceEndTimeDuration: number;
  repeat?: boolean;
}

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  maintenanceEndTimeDuration,
  repeat = false,
}) => {
  const [maintenanceEndTime, setMaintenanceEndTime] = useState(
    getMaintenanceEndTimeFromDuration(maintenanceEndTimeDuration)
  );

  const navigate = useNavigate();

  const calculateTimeLeft = (): TimeLeft | null => {
    const difference =
      new Date(maintenanceEndTime).getTime() - new Date().getTime();
    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return null;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(
    calculateTimeLeft()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [maintenanceEndTime]);

  const formatTime = (time: number): string => {
    return String(time).padStart(2, '0');
  };

  useEffect(() => {
    if (timeLeft === null) {
      if (repeat) {
        setMaintenanceEndTime(
          getMaintenanceEndTimeFromDuration(maintenanceEndTimeDuration)
        );
      } else {
        navigate('/', { replace: true });
        sessionStorage.clear();
      }
    }
  }, [timeLeft, repeat]);

  return (
    <div>
      {timeLeft || repeat ? (
        <span className="countdown">
          {formatTime(timeLeft?.hours || 0)}:
          {formatTime(timeLeft?.minutes || 0)}:
          {formatTime(timeLeft?.seconds || 0)}
        </span>
      ) : (
        <span>Maintenance is over!</span>
      )}
    </div>
  );
};

export default CountdownTimer;
